<script lang="ts">
    import { onMount } from 'svelte';
    import EmojiButton from '@joeattardi/emoji-button';

    import type { Data } from '@/interfaces/types';
    import PostHeader from '@/Pages/postbox/PostHeader.svelte';
    import PostContent from '@/Pages/postbox/PostContent.svelte';
    import PostMedia from '@/Pages/postbox/PostMedia.svelte';
    import PostFooter from '@/Pages/postbox/PostFooter.svelte';
    import PostComments from '@/Pages/postbox/PostComments.svelte';

    import { CommentsPaginator } from '@/utility/commentsPaginator';

    export let data: Data;
    export let authUser: {};
    export let Post: any;
    export let PostsPaginator: any;
    export let app: any;
    export let lists: any;
    export let reportStatuses: [];
    export let loggedUserAvailableAmount: number;
    export let minPostDeletionLimit: number;
    export let creatorCanEarnMoney: boolean;
    export let paymentSettings;
    let commentsVisible = false;
    let commentsPaginator: any;
    $: post = data.post || data;
    $: user = data.user;
    $: currentUser = data.currentUser || authUser;
    $: siteSettings = data.siteSettings || {};
    $: allowOpenProfiles = siteSettings.allowOpenProfiles || false;
    $: routeName = data.routeName;
    $: isAppleDevice = data.isAppleDevice;
    $: userLists = data.userLists;
    let totalComments = 0;

    function handleCommentsLoaded(event: CustomEvent) {
        totalComments = event.detail.total;
        post = { ...post, comments_count: totalComments };
    }

    function isPostUnlocked(): boolean {
        return (
            Boolean(data.isSubbed) ||
            (Boolean(allowOpenProfiles) && Boolean(post.user.open_profile)) ||
            (currentUser != null && currentUser.id === post.user_id) ||
            Boolean(data.userPaidForPost) ||
            (currentUser != null && currentUser.role_id === 1) ||
            post.price === '0'
        );
    }

    function shouldShowLockedContent(): boolean {
        return !isPostUnlocked() && post.price > 0;
    }

    function canShowTipButton(): boolean {
        return currentUser != null && post.user.id !== currentUser.id;
    }

    const baseUrl = window.location.origin;

    onMount(() => {
        commentsPaginator = new CommentsPaginator(baseUrl + '/posts/comments');
    });

    function toggleComments(event: CustomEvent) {
        const postId = event.detail.postId;
        commentsVisible = !commentsVisible;

        if (commentsVisible) {
            commentsPaginator.loadResults(postId, 9);
            initEmojiPicker(postId);
        }
    }

    function initEmojiPicker(postId: string) {
        try {
            const button = document.querySelector(`*[data-postID="${postId}"] .trigger`);
            const picker = new EmojiButton({
                position: 'top-end',
                theme: app.theme,
                autoHide: false,
                rows: 4,
                recentsCount: 16,
                emojiSize: '1.3em',
                showSearch: false,
            });

            picker.on('emoji', (emoji) => {
                const textarea = document.querySelector(
                    `*[data-postID="${postId}"] .comment-textarea`
                ) as HTMLTextAreaElement;
                if (textarea) {
                    textarea.value += emoji;
                }
            });

            if (button) {
                button.addEventListener('click', () => {
                    picker.togglePicker(button);
                });
            }
        } catch (e) {
            console.error('Error initializing emoji picker:', e);
        }
    }
</script>

<div class="post-box" data-postID="{post.id}" id="post_box_{post.id}">
    <PostHeader
        {post}
        {currentUser}
        {data}
        {siteSettings}
        {reportStatuses}
        {userLists}
        {user}
        {PostsPaginator}
        {minPostDeletionLimit}
    />
    <PostContent {post} {currentUser} {siteSettings} {data} {Post} />
    <PostMedia
        {post}
        {currentUser}
        {isAppleDevice}
        {loggedUserAvailableAmount}
        {data}
        {siteSettings}
        {isPostUnlocked}
        {shouldShowLockedContent}
    />
    <PostFooter
        {post}
        {currentUser}
        {data}
        {routeName}
        {Post}
        {PostsPaginator}
        {isPostUnlocked}
        {canShowTipButton}
        {totalComments}
        {loggedUserAvailableAmount}
        {creatorCanEarnMoney}
        {paymentSettings}
        on:toggleComments="{toggleComments}"
    />
    <PostComments
        {post}
        {currentUser}
        {data}
        {routeName}
        {PostsPaginator}
        bind:commentsVisible
        on:commentsLoaded="{handleCommentsLoaded}"
    />
</div>
